var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm.$route.meta.title[_vm.$i18n.locale]))]),_c('a-row',{staticClass:"flex-no__wrap wanted-search-input-wrapper",attrs:{"type":"flex"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'interactiveServices-create' })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Добавить ")],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.form,"loading":_vm.loading,"pagination":false,"scroll":{ x: 2200, y: 600 },"row-key":(record) => record.id},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('router-link',{staticClass:"td-post-item",staticStyle:{"padding":"0"},attrs:{"to":{
          name: 'interactiveInfo-detail',
          params: {
            id: item.id
          }
        },"tag":"span"}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"photo",fn:function(item){return [(!item.icon)?_c('div',[_vm._v("no photo")]):_c('div',[_c('img',{staticStyle:{"width":"40px","height":"40px","object-fit":"cover"},attrs:{"alt":"profile_img","src":item.icon}})])]}},{key:"name",fn:function(item){return [_c('router-link',{staticClass:"td-post-item",attrs:{"to":{
          name: 'interactiveServices-detail',
          params: {
            id: item.id
          }
        },"tag":"span"}},[_vm._v(_vm._s(item.title || "-"))])]}},{key:"link",fn:function(item){return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"short_content",fn:function(item){return [(item.short_content === null)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(item.short_content)+" ")])]}},{key:"group",fn:function(item){return [(item.group === null)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(item.group)+" ")])]}},{key:"order",fn:function(item){return [_c('a-input-number',{staticStyle:{"width":"60px","margin-right":"10px"},attrs:{"min":1},on:{"pressEnter":function($event){return _vm.updatePosition(item, $event)}},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"status",fn:function(item){return [_c('a-tag',{attrs:{"color":item.is_active ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(item.is_active ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")])]}},{key:"created_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"updated_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("LLL"))+" ")]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'interactiveServices-detail',
            params: {
              id: item.id
            }
          })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),_c('a-popconfirm',{attrs:{"cancel-text":"Нет","ok-text":"Да","title":"Вы действительно хотите удалить?"},on:{"confirm":function($event){return _vm.removeItem(item.id)}}},[_c('a-button',{attrs:{"type":"danger"}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{key:_vm.limit,attrs:{"current":_vm.page,"default-page-size":_vm.limit,"total":_vm.count || 0},on:{"change":_vm.setPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }